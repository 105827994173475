

.background-wrapper{
  width: 100%;
  height: auto;
  /* background-image: url('../src/components/images/portada0.jpg'); */
  /* background-position: center cover; */
}

.back-image{
  width: 100%;
  height: auto;
  justify-content: center;
}
.back-image img{
  width: 100%;
  height: auto;
}
.back-image-mobile{
  display: none;
}

@media (max-width:700px){
  .back-image-mobile{
    position: absolute;
    top: 80px;
    left: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}